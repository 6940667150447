export const useSearchStore = defineStore(
    "search",
    () => {
        const config = useRuntimeConfig();

        const data = reactive({
            searching: false,
            loading: true,
            selected_parks: [],
            selected_nights: null,
            selected_parent_slug: null,
            selected_parent_filter: null,
            selected_child_filters: [],
            selected_flexible_dates: 0,
            selected_date: [],
            currently_amending: null,
            selected_guests: [
                {
                    type: "adult",
                    description: "Adult (16+)",
                    plural: "Adults",
                    number: 1,
                    max_number: 2,
                    occupies_berths: true,
                    guest_id: 1,
                },
                {
                    type: "child",
                    description: "Children (2-15 years)",
                    plural: "Children",
                    number: 0,
                    max_number: 2,
                    occupies_berths: true,
                    guest_id: 2,
                },
                {
                    type: "infant",
                    description: "Infant (under 2 years)",
                    plural: "Infants",
                    number: 0,
                    max_number: 2,
                    occupies_berths: false,
                    guest_id: 3,
                },
                {
                    type: "pet",
                    description: "Pets",
                    plural: "Pets",
                    number: 0,
                    max_number: 2,
                    occupies_berths: false,
                    guest_id: null,
                },
            ],
            promo_code: null,
            filter_data: [],
            park_data: [],
            products_data: [],
            min_night_rules: null
        });


        // Computed property for availability rules
        const availability_rules = computed(() => {
            const selectedNights = data.selected_nights;

            const rules = data.selected_child_filters.map((item) => {
                const filter = data.filter_data.find((filter) => filter.id == item);

                return {
                    id: filter.id,
                    filter: filter.name,
                    number_of_nights: [...new Set(filter.availability_rules.map(rule => rule.number_of_nights))], // Flattened unique nights
                    arrival_days:
                        filter.availability_rules.find((rule) => rule.number_of_nights == selectedNights)
                            ?.arrival_days || [],
                };
            });

            const arrivalDays = rules.map((rule) => rule.arrival_days);

            return {
                rules,
                selectable_days: [...new Set(arrivalDays.flat())]
            };
        });


        const filtered_parks = computed(() => {
            // Child filters have been applied
            if (data.selected_child_filters.length) {
                return data.park_data.filter((park) =>
                    park.filters.some((filter) => data.selected_child_filters.includes(filter)),
                );
            }
            // Check for primary filter
            return data.park_data.filter((park) =>
                park.filters.some((filter) => data.selected_parent_filter == filter),
            );
        });

        const fetchFilterData = async () => {


            try {
                const response = await fetch(`/api/search/park-filters`);
        
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
        
                const responseData = await response.json();
                
                console.log('fetch filters:',responseData)

                // Define mapping of slugs to search_type
                const searchTypeMap = {
                    'glamping-pods': 2,
                    'camping-pitch': 2,
                    'cottages': 1,
                    'glamping-pods-touring-camping': 2,
                    'holiday-caravans': 1,
                    'holiday-rentals': 1,
                    'lodges': 1,
                    'touring-motorhomes-pitch': 2,
                    'touring-camping': 2
                };
        
                // Add search_type based on slug mapping
                data.filter_data = responseData.map(filter => ({
                    ...filter,
                    search_type: searchTypeMap[filter.slug] || null
                }));
        
                data.selected_parent_filter = data.filter_data.find(filter => filter.is_parent)?.id;
            } catch (error) {
                console.error("Failed to fetch filter data:", error);
            }
        };
        
        const fetchMinNightRules = async () => {
            try {
                const response = await fetch(`/api/search/min-night-rules`);

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const responseData = await response.json();
                data.min_night_rules = responseData;
            } catch (error) {
                console.error("Failed to fetch filter data:", error);
            }    
        }

        const fetchParkData = async () => {
            try {
                const response = await fetch(`/api/search/parks`);

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const responseData = await response.json();
                data.park_data = responseData;
            } catch (error) {
                console.error("Failed to fetch filter data:", error);
            }
        };

        const fetchProductWPData = async () => {
            try {
                const response = await fetch(`/api/search/products`);

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const responseData = await response.json();
                data.products_data = responseData;
            } catch (error) {
                console.error("Failed to fetch filter data:", error);
            }
        };


    
        const getSearchResults = async () => {
        
            try {
                const startTime = performance.now()

                const parkIDs = data.selected_parks.map((park) => park.id);
        
                if (!data.selected_date.length) {
                    console.error("Please select a date range before continuing");
                    return;
                }
        
                if (parkIDs.length === 0) {
                    console.error("No parks selected for the search.");
                    return;
                }

                console.log(`✅ Checkpoint: Initial Setup Completed - ${((performance.now() - startTime) / 1000).toFixed(3)}s`);
        
                const selectedSearchTypes = data.selected_child_filters
                    .map((selectedId) => data.filter_data.find((f) => f.id === selectedId))
                    .filter(Boolean); // Remove null/undefined values
        
                // Get all selected search type names in lowercase and include slugs
                const selectedFilters = selectedSearchTypes.map((type) => ({
                    name: type.name.toLowerCase(),
                    slug: type.slug
                }));
        
                console.log("✅ Checkpoint: Selected Filters", selectedFilters);
        
                const allResults = [];

                if (data.selected_nights) {
                    const startDate = new Date(data.selected_date[0]);
                    const endDate = new Date(startDate);
                    endDate.setDate(startDate.getDate() + data.selected_nights);
                    data.selected_date = [startDate, endDate];
                    console.log("✅ Checkpoint: Force dates", data.selected_date);
                }
        
                // Get the weekday (0 = Sunday, 1 = Monday, ..., 6 = Saturday) from selected start date
                const selectedStartDay = new Date(data.selected_date[0]).getDay();
                console.log("✅ Checkpoint: Selected Start Day", selectedStartDay);
        
                for (const { search_type, name } of selectedSearchTypes) {
                    for (const parkID of parkIDs) {
                        const params = {
                            parkID,
                            from: data.selected_date[0],
                            to: data.selected_date[1],
                            daysEitherSide: data.selected_flexible_dates,
                            petsCount: data.selected_guests.find((guest) => guest.type === "pet")?.number || 0,
                            bookingProductTypeID: search_type,
                            promotionCode: data.promo_code || "",
                            peopleByAge: data.selected_guests
                                .filter((guest) => guest.type !== "pet")
                                .map((guest) => ({
                                    ID: guest.guest_id,
                                    Description: guest.description,
                                    NumberOfGuests: guest.number,
                                    OccupiesBerths: guest.occupies_berths,
                                })),
                        };
        
                        try {
                            const response = await fetch("/api/search/FindMatchingAvailableProducts", {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json",
                                },
                                body: JSON.stringify(params),
                            });
        
                            if (!response.ok) {
                                throw new Error(`HTTP error! status: ${response.status}`);
                            }
        
                            const responseData = await response.json();
                            console.log("✅ Checkpoint: API Response", responseData);
        
                            if (responseData && responseData.Products) {
                                let filteredProducts = responseData.Products;
                                console.log("✅ Checkpoint: Initial Products Count", filteredProducts.length);
        
                                filteredProducts = filteredProducts.filter((product) => {
                                    let shouldKeep = true;
                                
                                    // ✅ If all three categories are selected → Show everything, no filtering needed
                                    if (
                                        selectedFilters.some(filter => filter.slug === "camping-pitch") &&
                                        selectedFilters.some(filter => filter.slug === "glamping-pods-touring-camping") &&
                                        selectedFilters.some(filter => filter.slug === "touring-motorhomes-pitch")
                                    ) {
                                        console.log("✅ Checkpoint: All three categories selected - No filtering applied");
                                        return true;
                                    }
                                
                                    // ✅ Glamping Pods Special Case (glamping-pods only → REMOVE all 'Pitch' products)
                                    if (
                                        selectedFilters.some(filter => filter.slug === "glamping-pods")
                                    ) {
                                        if (product.ProductDescription.toLowerCase().includes("pitch")) {
                                            return false; // ❌ Remove all "Pitch" products
                                        }
                                        return true; // ✅ Keep everything else
                                    }
                                
                                    // ✅ Multiple Categories Logic
                                
                                    // Camping + Glamping → Show Pods, Dens, and Pitches EXCEPT Touring
                                    if (
                                        selectedFilters.some(filter => filter.slug === "camping-pitch") &&
                                        selectedFilters.some(filter => filter.slug === "glamping-pods-touring-camping")
                                    ) {
                                        if (product.ProductDescription.toLowerCase().includes("touring")) {
                                            return false; // ❌ Remove anything with "Touring"
                                        }
                                        return true;
                                    }
                                
                                    // Camping + Touring → Show ONLY "Touring" & Pitches, remove Pods & Dens
                                    if (
                                        selectedFilters.some(filter => filter.slug === "camping-pitch") &&
                                        selectedFilters.some(filter => filter.slug === "touring-motorhomes-pitch")
                                    ) {
                                        if (
                                            product.ProductDescription.toLowerCase().includes("pod") || 
                                            product.ProductDescription.toLowerCase().includes("den")
                                        ) {
                                            return false; // ❌ Remove Pods & Dens
                                        }
                                        return true;
                                    }
                                
                                    // Glamping + Touring → Keep Pods & Dens, remove Pitches that aren’t Touring
                                    if (
                                        selectedFilters.some(filter => filter.slug === "glamping-pods-touring-camping") &&
                                        selectedFilters.some(filter => filter.slug === "touring-motorhomes-pitch")
                                    ) {
                                        if (
                                            product.ProductDescription.toLowerCase().includes("pitch") &&
                                            !product.ProductDescription.toLowerCase().includes("touring")
                                        ) {
                                            return false; // ❌ Remove Pitches that aren’t Touring
                                        }
                                        return true;
                                    }
                                
                                    // ✅ Single Category Logic
                                
                                    if (
                                        selectedFilters.some(filter => filter.slug === "camping-pitch") &&
                                        selectedFilters.length === 1
                                    ) {
                                        if (
                                            ["pod", "den", "touring", "accessible"].some(term => 
                                                product.ProductDescription.toLowerCase().includes(term)
                                            )
                                        ) {
                                            return false; // ❌ Remove Pods, Dens, Touring, and Accessible
                                        }
                                    }
                                    
                                
                                    if (
                                        selectedFilters.some(filter => filter.slug === "touring-motorhomes-pitch") &&
                                        selectedFilters.length === 1
                                    ) {
                                        if (!product.ProductDescription.toLowerCase().includes("touring")) {
                                            return false; // ❌ Remove anything that doesn’t contain "Touring"
                                        }
                                    }
                                
                                    if (
                                        selectedFilters.some(filter => filter.slug === "glamping-pods-touring-camping") &&
                                        selectedFilters.length === 1
                                    ) {
                                        if (
                                            !product.ProductDescription.toLowerCase().includes("pod") &&
                                            !product.ProductDescription.toLowerCase().includes("den")
                                        ) {
                                            return false; // ❌ Remove everything that isn't a Pod or Den
                                        }
                                    }

                                    // ✅ Lodges 
                                    if (
                                        selectedFilters.some(filter => filter.slug === "lodges") &&
                                        selectedFilters.length === 1
                                      ) {
                                        // ✅ Only lodges selected — restrict to only "lodge" results
                                        return product.ProductDescription.toLowerCase().includes("lodge");
                                      }
                                      
                                    
                                
                                    return shouldKeep;
                                });
                                
                                console.log("✅ Checkpoint: After Category Filtering", filteredProducts.length);
                                
        
                                filteredProducts = filteredProducts.filter((product) => {
                                    const productDay = new Date(product.FromDate).getDay();
                                    return productDay === selectedStartDay;
                                });
        
                                console.log("✅ Checkpoint: After Date Filtering", filteredProducts.length);
        
                                const disallowedIDs = ["28", "86", "96", "106", "108", "35", "42", "43", "45", "46", "49", "50", "93", "27"];
                                filteredProducts = filteredProducts.filter((product) => 
                                    !disallowedIDs.includes(String(product.ID))
                                );
        
                                console.log("✅ Checkpoint: After Disallowed ID Filtering", filteredProducts.length);
        
                                allResults.push(...filteredProducts);

                                
                            }
        
                            console.log(`✅ Checkpoint: Final Filtered Results - ${((performance.now() - startTime) / 1000).toFixed(3)}s`, allResults.length, allResults);
                        } catch (err) {
                            console.error(`❌ Failed to fetch products for parkID ${parkID} with searchType ${search_type}:`, err);
                        }
                    }
                }
        
                return allResults;
            } catch (error) {
                console.error("❌ Failed to fetch search results:", error);
            }
        };
        
                

        // Check if the park is selected (used for the checkbox)
        const isParkSelected = (park) => {
            return data.selected_parks.some((selected) => selected.id === park.id);
        };

        const getWpParkData = (parkID) => {
            const filteredParks = data.park_data.filter(park => park.id == parkID);
            return filteredParks.length ? filteredParks[0] : null;
        }
          
        const getWpProductData = (productID) => {
            const filteredProducts = data.products_data.filter(product => product.id == productID);
            return filteredProducts.length ? filteredProducts[0] : null;
        }

        // Toggle the selection of the park
        const toggleSelection = (park) => {
            const index = data.selected_parks.findIndex((selected) => selected.id === park.id);

            if (index > -1) {
                data.selected_parks.splice(index, 1);
            } else {
                data.selected_parks.push(park);
            }
        };

        const loadingComplete = () => {
            data.loading = false;
        }

        const searchStart = () => {
            data.searching = true;
        }

        const searchingComplete = () => {
            data.searching = false;
        }

        const guest_count = computed(() => {
            return data.selected_guests.filter(guest => guest.type !== "pet").reduce((total, guest) => total + guest.number, 0);
        });

        const pet_count = computed(() => {
            return data.selected_guests.filter(guest => guest.type == "pet").reduce((total, guest) => total + guest.number, 0);
        });

        const can_user_search = computed(() => {
            const hasSelectedPark = filtered_parks.value.length > 0;
            const hasSelectedDate = data.selected_date.length > 0;
            const hasSelectedParkInData = data.selected_parks.length > 0;
            
            // Check if at least one adult is selected
            const hasAdult = data.selected_guests.some(guest => guest.type === "adult" && guest.number > 0);
          
            return hasSelectedPark && hasSelectedDate && hasSelectedParkInData && hasAdult;
          });
          

        const can_user_select_parks = computed(() => {
            return data.selected_child_filters.length > 0 && filtered_parks.value.length > 0;
        });

        const can_user_select_dates = computed(() => {
            return filtered_parks.value.length > 0 && data.selected_parks.length > 0;
        });

        const errors = computed(() => {
            let errors = [];

            if(!data.selected_child_filters.length) {
                errors.push({
                    field: "selected_child_filters",
                    message: "Please select at least one accommodation type.",
                });
            }

            if (!data.selected_parks.length) {
                errors.push({
                    field: "selected_parks",
                    message: "Please select at least one park.",
                });
            }

            if (!data.selected_date.length) {
                errors.push({
                    field: "selected_date",
                    message: "Please select a date range.",
                });
            }

            if(!data.selected_child_filters.length) {
                errors.push({
                    field: "selected_child_filters",
                    message: "Please select at least one accommodation type.",
                });
            }

            return errors;
        });

        const guest_summary = computed(() => {
            return data.selected_guests
                .filter((guest) => guest.number > 0) // Only include guests with a count greater than 0
                .map((guest) => `${guest.number} ${guest.number > 1 ? guest.plural : guest.description}`)
                .join(", ")
                .toLowerCase();
        });

        return {
            data,
            availability_rules,
            filtered_parks,
            errors,
            guest_count,
            pet_count,
            guest_summary,
            can_user_search,
            can_user_select_parks,
            can_user_select_dates,
            getSearchResults,
            getWpParkData,
            getWpProductData,
            fetchFilterData,
            fetchParkData,
            fetchMinNightRules,
            fetchProductWPData,
            isParkSelected,
            toggleSelection,
            loadingComplete,
            searchStart,
            searchingComplete
        };
    },
    {
        persist: {
            storage: persistedState.localStorage
        },
    },
);